/*
 * @Author: ChenYaJin
 * @Date: 2023-07-20 09:29:00
 * @LastEditors: ChenYaJin
 * @LastEditTime: 2023-11-10 10:47:09
 * @Description: 数据序列化
 */
import { BehaviorAction, type IBehaviorAction } from '@/models/behavior'
import { type IEmployee, Employee } from '@/models/employee'
import { type IProject, Project } from '@/models/project'

export function postProjectDataNormalize(project: Project): IProject {
  const {
    name,
    type,
    belowType,
    way,
    belowWay,
    supportability,
    insured,
    annualReceivable,
    amateurIncome,
    address,
    contractStartAt,
    contractEndAt,
    elevatorsTotal,
    housesTotal,
    extent,
    staffTotal,
    startingAt,
    enterpriseId,
    contract,
    provinceCode,
    cityCode,
    districtCode,
  } = project
  return {
    name,
    type,
    belowType,
    way,
    belowWay,
    supportability: Number(supportability),
    insured: Number(insured),
    annualReceivable: Number(annualReceivable),
    amateurIncome: Number(amateurIncome),
    address,
    contractStartAt: contractStartAt ? contractStartAt + ' 00:00:00' : '',
    contractEndAt: contractEndAt ? contractEndAt + ' 00:00:00' : '',
    elevatorsTotal: Number(elevatorsTotal),
    housesTotal: Number(housesTotal),
    extent: Number(extent),
    staffTotal: Number(staffTotal),
    startingAt: startingAt + ' 00:00:00',
    enterpriseId,
    contract,
    provinceCode,
    cityCode,
    districtCode,
  }
}

export function postEmployeeDataNormalize(employee: Employee): IEmployee {
  const { name, identityCardNum, documentPhoto } = employee
  return { name, identityCardNum, documentPhoto }
}

export function postIBehaviorDataNormalize(employee: BehaviorAction): IBehaviorAction {
  const { sketch, recognitionOrgan, recognition, provePhoto } = employee
  return {
    sketch,
    recognitionOrgan,
    recognition: recognition ? recognition + ' 00:00:00' : '',
    provePhoto,
  }
}
